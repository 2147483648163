.root {
  position: relative;
  border: 1px solid #000;
  color: black;
  background-color: white;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 3px 3px 0px 0px black;
  top: -3px;
  left: -3px;
  margin-top: 3px;
  margin-left: 3px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root:focus {
  outline: none;
}

.root:active {
  box-shadow: none;
  top: 0;
  left: 0;
}

.lg {
  height: 30px;
  width: 30px;
}

.md {
  height: 20px;
  width: 20px;
}

.sm {
  height: 20px;
  width: 20px;
}
