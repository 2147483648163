.root {
  /* border: 3px solid #ffffff; */
  /* position: relative;
  border: 1px solid #000;
  color: #fff; */
  /* background-color: #000; */
  /* font-weight: 500; */
  /* cursor: pointer; */
  /* box-shadow: 3px 3px 0px 0px #189ad3;
  top: -3px;
  left: -3px;
  margin-top: 3px;
  margin-left: 3px; */
}

.root:focus {
  /* outline: none; */
}

.root:active {
  box-shadow: none;
  top: 0;
  left: 0;
}

.lg {
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
}

.md {
  padding: 0.25rem 1.5rem;
  font-size: 1rem;
}

.sm {
  padding: 0.15rem 1rem;
  font-size: 0.8rem;
}
