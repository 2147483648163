@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Outfit', serif;
}

body {
  background-color: white;
}
